import Indicator from 'components/indicator'
import Table from 'components/Table'
import { reportingClinicianPatient } from 'mocks/table'
import React, { useEffect, useState } from 'react'
import styles from 'pages/Admin/index.module.scss';
import DatePickerInput from 'components/FormInput/DatePickerInput';
import SearchBox from 'components/SearchBox/SearchBox';
import style from './reportingClinician.module.scss';
import { useDispatch } from 'react-redux';
import { fetchPatientReportAssignedAction } from 're-ducks/reportingClinicians';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import FormatDate from 'helpers/formatDate';
import moment from 'moment';
import Pagination from 'components/Pagination/Pagination';
import getTotalPages from 'helpers/getTotalPages';

const PatientsTable = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState([])
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  

  const getPatientsAssigned = () => {
    setLoading(true);
    dispatch(
      fetchPatientReportAssignedAction(
        {
          id: id,
          params: {
            search: searchValue,
            from_date: startDate !== null ? moment(startDate)?.format('YYYY-MM-DD') : '',
            to_date: endDate !== null ? moment(endDate)?.format('YYYY-MM-DD') : '',
          }
        },
        (res) => {
          setData(res?.data);
          setLoading(false);
        },
        (err) => {
          toast.error(err);
          setLoading(false)
        }
      )
    )
  }

  useEffect(() => {
    getPatientsAssigned()
  }, [searchValue, startDate && endDate])

  return (
    <div className={style.patientDetails}>
      <h2 className={style.patientDetails__heading}>Patient Details</h2>
          <div className={style.patientDetails__actionBox}>
            <SearchBox 
              placeholder="Search by name"
              onChange={(e) => {
                setSearchValue(e);
              }}
              searchValue={searchValue}
            />
            <div className={style.patientDetails__date}>
              <DatePickerInput
                selected={startDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="From:"
              />
              <DatePickerInput
                selected={endDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="To:"
              />
            </div>
          </div>
      <Table
        headers={reportingClinicianPatient}
        tableData={data?.reporting_clinician}
        placeholderText="patients"
        loading={loading}
      >
        {(row) => {
          return(
            <>
              <td>{row.patient_name}</td>
              <td>{row.procedures}</td>
              <td>{FormatDate(row.visit_date)}</td>
              <td>{row.center_name}</td>
              <td>
                <div className={styles.indicatorWrapper}>
                  <Indicator isActive={row?.report_status} />
                  <span>{row?.status === "awaiting_report" ? "Awaiting Report" : row?.report_status}</span>
                </div>
              </td>

            </>
          )
        }}
      </Table>
      <Pagination
        totalPage={getTotalPages(data?.total, 50)}
        forcePage={page - 1}
        handlePageInput={(e) => setPage(e)}
        currentPage={page}
       />
    </div>
  )
}

export default PatientsTable