import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';
import React, { lazy } from 'react';
import { AssignAdmin } from 'container/Centers/CreateCenters/AssignAdmin';
import ReportingClinicianDetail from 'container/Admin/AdminUsers/ReportClinicians/ReportingClinicianDetail';

export const AdminPage = lazy(() => import('pages/Admin/Admin'));
export const AddAdminPage = lazy(() => import('pages/Admin/AddAdmin'));

export default function AdminRoutes() {
  return (
    <Routes>
      <Route
        path="/admin-management"
        element={
          <PrivateRoute>
            <AdminPage />
          </PrivateRoute>
        }
      />
      <Route
        path="add-admin"
        element={
          <PrivateRoute>
            <AddAdminPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/:id/assign-admin"
        element={
          <PrivateRoute>
            <AssignAdmin />
          </PrivateRoute>
        }
      />
        <Route
        path="/admin-management/reporting-clinician/:id"
        element={
          <PrivateRoute>
            <ReportingClinicianDetail />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
