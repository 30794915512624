import DashboardLayout from 'container/layouts/dashboard';
import React, { useEffect, useState } from 'react';
import Back from 'assets/icons/back-icon.svg';
import styles from './reportingClinician.module.scss';
import Dropdown from 'components/Dropdown/Dropdown';
import { FaEllipsisVertical } from "react-icons/fa6";
import { Option } from 'components/Dropdown/Option';
import Indicator from 'components/indicator';
import PatientsTable from './PatientsTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchSingleReportingCliniciansAction, updateReportingClinicianStatusActions } from 're-ducks/reportingClinicians';
import { FormatDate } from 'helpers/formatDateTime';
import { toast } from 'react-toastify';

const ClinicianInfo = ({ title, caption, status, fetchSingleReportingClinician }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const updateStatus = () => {
    dispatch(
      updateReportingClinicianStatusActions(
        {
          id: params?.id,
          data: {
            status: status === 'active' ? 'deactivated' : 'active'
          }
        },
        (success) => {
          if (success?.message) {
            toast.success(success.message);
          } else {
            toast.success("Status updated successfully");
          }
          fetchSingleReportingClinician(); // Refresh clinician data
        },
        (err) => {
          console.error("Update Error:", err);
          toast.error(err || "Failed to update status");
        }
      )
    );
  };

  return (
    <div className={styles.clinicanInfoContainer__details}>
      <h5>{title}</h5>
      {!status ? (
        <p>{caption}</p>
      ) : (
        <div className={styles.clinicanInfoContainer__details__status}>
          <div className={styles.clinicanInfoContainer__details__indicator}>
            <Indicator isActive={status} />
            <span>{status}</span>
          </div>
          {status !== "pending" ?<Dropdown content={
            <>
              <Option onClick={updateStatus}>
                {status === "active" ? "Deactivate" : "Activate"}
              </Option>
            </>
          }>
            <div>
              <FaEllipsisVertical style={{
                fontSize: '14px',
                color: 'white',
                marginTop: '5px'
              }} />
            </div>
          </Dropdown> : ""}
        </div>
      )}
    </div>
  );
};

const ReportingClinicianDetail = () => {
  const [clinician, setClinician] = useState();
  const [refresh, setRefresh] = useState(false); // Refresh state for re-rendering
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const fetchSingleReportingClinician = () => {
    dispatch(
      fetchSingleReportingCliniciansAction(
        { id: params?.id },
        (res) => {
          setClinician(res?.data);
        },
        (err) => console.error("Fetch Error:", err)
      )
    );
  };

  useEffect(() => {
    fetchSingleReportingClinician();
  }, [refresh]); // Re-fetch when refresh state changes

  return (
    <DashboardLayout>
      <div>
        <div className={styles.reportClinicianContainer__heading}>
          <img src={Back} onClick={() => navigate(-1)} alt="Back" />
          <h2>Clinician Details</h2>
        </div>
        <div className={styles.clinicanInfoContainer}>
          <ClinicianInfo title="Clinician Name" caption={clinician?.clinician_name} />
          <ClinicianInfo title="Email Address" caption={clinician?.email} />
          <ClinicianInfo title="Phone no." caption={clinician?.phone_number || '-'} />
          <ClinicianInfo 
            title="Status" 
            status={clinician?.status} 
            fetchSingleReportingClinician={() => setRefresh((prev) => !prev)} // Trigger re-fetch
          />
          <ClinicianInfo title="Date Created" caption={FormatDate(clinician?.created_at)} />
          <ClinicianInfo title="Specialty" caption={clinician?.speciality} />
        </div>
        <div className={styles.patientDetails}>
          
          <div>
            <PatientsTable />
            {/* <Pagination /> */}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ReportingClinicianDetail;
