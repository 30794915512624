import { API } from 'const';
import { takeEvery, call } from 'redux-saga/effects';
import api from 'services/api';
import actionTypes from './reportingClinician.types';
import queryFormatter from 'helpers/queryFormatter';

export function* fetchAllReportingClinicians(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.REPORTING_CLINICIAN}/reporting-clinician-list?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* updateReportingClinicianStatus(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.REPORTING_CLINICIAN}/status/${data?.id}`, data?.data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fetchSingleReportingClinicians(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.REPORTING_CLINICIAN}/${data.id}/reporting-clinician-details`)
    );
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* editReportingClinician(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() => api.put(`${API.REPORTING_CLINICIAN}/${data?.id}/edit-reporting-clinician`, data?.data));
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}

export function* fetchAllPatientReportAssigned(action) {
  try {
    const { cb, payload: data } = action;
    const res = yield call(() =>
      api.get(`${API.REPORTING_CLINICIAN}/${data.id}/reports?${queryFormatter(data.params)}`)
    );
    if (res.status === 200) {
      cb(res.data);
    }
  } catch (err) {
    const { cbError } = action;
    const alert = err?.response?.data?.message || '';
    cbError(alert);
  }
}


function* watchReportingClinicianSaga() {
  yield takeEvery(actionTypes.FETCH_ALL_REPORTING_CLINICIANS, fetchAllReportingClinicians);
  yield takeEvery(actionTypes.UPDATE_REPORTING_CLINICIAN_STATUS, updateReportingClinicianStatus);
  yield takeEvery(actionTypes.SINGLE_REPORTING_CLINICIAN, fetchSingleReportingClinicians);
  yield takeEvery(actionTypes.EDIT_REPORTING_CLINICIAN, editReportingClinician);
  yield takeEvery(actionTypes.FETCH_PATIENT_REPORT_ASSIGNED, fetchAllPatientReportAssigned);
}

export default watchReportingClinicianSaga;

