import reportingClinicianTypes from './reportingClinician.types';

export const fetchAllReportingCliniciansActions = (payload, cb, cbError) => ({
  type: reportingClinicianTypes.FETCH_ALL_REPORTING_CLINICIANS,
  payload,
  cb,
  cbError
});

export const updateReportingClinicianStatusActions = (payload, cb, cbError) => ({
  type: reportingClinicianTypes.UPDATE_REPORTING_CLINICIAN_STATUS,
  payload,
  cb,
  cbError
});

export const fetchSingleReportingCliniciansAction = (payload, cb, cbError) => ({
  type: reportingClinicianTypes.SINGLE_REPORTING_CLINICIAN,
  payload,
  cb,
  cbError
});

export const editReportingClinicianAction = (payload, cb, cbError) => ({
  type: reportingClinicianTypes.EDIT_REPORTING_CLINICIAN,
  payload,
  cb,
  cbError
});

export const fetchPatientReportAssignedAction = (payload, cb, cbError) => ({
  type: reportingClinicianTypes.FETCH_PATIENT_REPORT_ASSIGNED,
  payload,
  cb,
  cbError
});
