import resultTrackingTypes from './result.types';

export const getAllResultTrackingActions = (payload, cb, cbError) => ({
  type: resultTrackingTypes.GET_ALL_RESULT_TRACKING,
  payload,
  cb,
  cbError
});

export const getSingleResultTrackingActions = (payload, cb, cbError) => ({
  type: resultTrackingTypes.GET_SINGLE_RESULT_TRACKING,
  payload,
  cb,
  cbError
});

export const getMultipleResultsActions = (payload, cb, cbError) => ({
  type: resultTrackingTypes.GET_MULTIPLE_RESULTS,
  payload,
  cb,
  cbError
});

export const updateResultTrackingActions = (payload, cb, cbError) => ({
  type: resultTrackingTypes.UPDATE_RESULT_TRACKING,
  payload,
  cb,
  cbError
});

export const completePatientResultActions = (payload, cb, cbError) => ({
  type: resultTrackingTypes.COMPLETE_PATIENT_RESULT,
  payload,
  cb,
  cbError
});
export const reassignReportingClinicianActions = (payload, cb, cbError) => ({
  type: resultTrackingTypes.REASSIGN_REPORTING_CLINICIAN,
  payload,
  cb,
  cbError
});
